import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Text } from '@/design-system/atoms/Text';
import { Title } from '@/design-system/atoms/Title';
import { LocationDetail } from '@/design-system/molecules/LocationDetail';

import styles from './FeaturedLocations.module.scss';

const FeaturedLocations = ({ htmlId, title, locationCards, buttonType, button, zip, ...props }) => {
    return (
        <section id={htmlId} className={styles['featured-locations']}>
            <ContentContainer>
                <div className={styles['featured-locations__content']}>
                    <div className={styles['featured-locations__header']}>
                        <Title
                            tag="h2"
                            variant={Title.VARIANT.H3}
                            content={title}
                            className={styles['featured-locations__title']}
                        />
                        {zip && (
                            <Text
                                tag="span"
                                variant="t2"
                                className={styles['featured-locations__zip']}
                                content={`Located around ${zip}`}
                            />
                        )}
                    </div>
                    <Grid className={styles['featured-locations__cards']}>
                        {locationCards.map((locationDetailCard, cardIdx) => {
                            return (
                                <GridColumn
                                    colSpan={{ sm: 4, md: 6, lg: 4 }}
                                    className={styles['featured-locations__card-column']}
                                    key={`location-detail-${cardIdx}`}
                                >
                                    <LocationDetail
                                        {...locationDetailCard}
                                        variant={LocationDetail.VARIANT.LIST_ITEM}
                                        analytics={{
                                            componentTitle: title,
                                        }}
                                    />
                                </GridColumn>
                            );
                        })}
                    </Grid>
                    {/* Button: option to select 'primary' or 'secondary' variant */}
                    {button?.label && (
                        <Button
                            buttonStyle={
                                buttonType === 'primary'
                                    ? Button.STYLE.PRIMARY
                                    : Button.STYLE.SECONDARY
                            }
                            aria-label={button.ariaLabel}
                            href={button.href}
                            className={styles['featured-locations__button']}
                            label={button.label}
                            analytics={{
                                componentName: 'Featured Locations',
                                componentTitle: title,
                                interactionType:
                                    buttonType === 'primary'
                                        ? 'location_primary_cta'
                                        : 'location_cta',
                            }}
                        />
                    )}
                </div>
            </ContentContainer>
        </section>
    );
};

FeaturedLocations.propTypes = {
    /** Title */
    title: PropTypes.string.isRequired,
    /** Location Cards */
    locationCards: PropTypes.arrayOf(PropTypes.shape(LocationDetail.propTypes)),
    /** Selected Button Type */
    buttonType: PropTypes.oneOf(['primary', 'secondary']),
    /** Button */
    button: PropTypes.shape(Button.propTypes),
    /** Zip code */
    zip: PropTypes.string,
};
export default FeaturedLocations;
