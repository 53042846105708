import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';

import DefaultImage from '@/assets/images/Locations_Placeholder@2x.png';
import addressPropType from '@/custom-prop-types/address';
import { workingHoursType } from '@/custom-prop-types/workingHours';
import { Button } from '@/design-system/atoms/Button';
import { IconDescription } from '@/design-system/atoms/IconDescription';
import { LazyIconArrowRightCircle } from '@/design-system/atoms/Icons/IconArrowRightCircle/Lazy';
import { LazyIconMapPin } from '@/design-system/atoms/Icons/IconMapPin/Lazy';
import { LazyIconNavigation } from '@/design-system/atoms/Icons/IconNavigation/Lazy';
import { LazyIconPhone } from '@/design-system/atoms/Icons/IconPhone/Lazy';
import { LazyIconX } from '@/design-system/atoms/Icons/IconX/Lazy';
import { Image } from '@/design-system/atoms/Image';
import { LocationOpenOrClosed } from '@/design-system/atoms/LocationOpenOrClosed';
import { Title } from '@/design-system/atoms/Title';
import Typography from '@/design-system/atoms/Typography';
import { useLocationWaitTime } from '@/hooks/useLocationWaitTime/useLocationWaitTime';
import { composeRefs } from '@/utils/composeRefs';
import { normalizeAddress } from '@/utils/normalizeAddress';
import { normalizeWorkingHours } from '@/utils/normalizeWorkingHours';

import styles from './LocationDetail.module.scss';

export const LocationDetail = React.forwardRef((props, ref) => {
    const {
        variant = LocationDetail.VARIANT.CARD,
        locationType,
        className,
        title,
        parentLocationName,
        rootLocationName,
        buildingName,
        address,
        phoneNumber,
        workingHours,
        temporarilyClosed,
        overrideMessage,
        cta,
        buildingImage,
        onCloseClick,
        analytics,
        waitTime: hasWaitTime,
        epicDepartmentId,
        coveoAnalyticsCallbacks,
        distance,
        ...otherProps
    } = props;

    const internalRef = React.useRef();

    const infoList = [
        [normalizeAddress(address), address?.googleMapsUrl, LazyIconMapPin],
        [phoneNumber, `tel:${phoneNumber}`, LazyIconPhone],
        [distance, null, LazyIconNavigation],
    ].filter(([content]) => !!content);

    const [, waitTime] = useLocationWaitTime({ locationType, hasWaitTime, epicDepartmentId });

    const analyticsObj = useMemo(() => {
        const { is24Hours, isOpen } = normalizeWorkingHours({ workingHours });
        return {
            componentName: 'location',
            locationName: title,
            locationParent: parentLocationName,
            locationAddress: normalizeAddress(address).replaceAll('\n', ','),
            locationPhone: phoneNumber,
            locationOpen: is24Hours ? 'Open 24 Hours' : isOpen ? 'Open' : 'Closed',
            locationWait: waitTime || '',
            ...analytics,
        };
    }, [title, parentLocationName, address, phoneNumber, workingHours, analytics, waitTime]);

    const handleClick = useCallback(() => {
        coveoAnalyticsCallbacks?.onClick?.();
    }, [coveoAnalyticsCallbacks]);

    const handleContextMenu = useCallback(() => {
        coveoAnalyticsCallbacks?.onContextMenu?.();
    }, [coveoAnalyticsCallbacks]);

    const handleMouseDown = useCallback(() => {
        coveoAnalyticsCallbacks?.onMouseDown?.();
    }, [coveoAnalyticsCallbacks]);

    const handleMouseUp = useCallback(() => {
        coveoAnalyticsCallbacks?.onMouseUp?.();
    }, [coveoAnalyticsCallbacks]);

    const handleTouchStart = useCallback(() => {
        coveoAnalyticsCallbacks?.onTouchStart?.();
    }, [coveoAnalyticsCallbacks]);

    const handleTouchEnd = useCallback(() => {
        coveoAnalyticsCallbacks?.onTouchEnd?.();
    }, [coveoAnalyticsCallbacks]);

    useEffect(() => {
        coveoAnalyticsCallbacks?.cancelPendingSelect?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={composeRefs(ref, internalRef)}
            className={classNames(
                styles['location-detail__wrapper'],
                styles[`location-detail__wrapper--${variant}`],
            )}
            {...otherProps}
        >
            <div
                className={classNames(
                    styles['location-detail'],
                    styles[`location-detail--${variant}`],
                    className,
                )}
            >
                <div className={styles['location-detail__details']}>
                    {title && cta?.href && (
                        <div>
                            <Button
                                className={styles['location-detail__title']}
                                label={title}
                                href={cta.href}
                                buttonStyle={Button.STYLE.TEXT_TITLE}
                                iconComponent={LazyIconArrowRightCircle}
                                analytics={{
                                    ...analyticsObj,
                                    interactionType: `${analyticsObj.componentName ?? ''}_name_cta`,
                                }}
                                onClick={coveoAnalyticsCallbacks ? handleClick : undefined}
                                onContextMenu={
                                    coveoAnalyticsCallbacks ? handleContextMenu : undefined
                                }
                                onMouseDown={coveoAnalyticsCallbacks ? handleMouseDown : undefined}
                                onMouseUp={coveoAnalyticsCallbacks ? handleMouseUp : undefined}
                                onTouchStart={
                                    coveoAnalyticsCallbacks ? handleTouchStart : undefined
                                }
                                onTouchEnd={coveoAnalyticsCallbacks ? handleTouchEnd : undefined}
                            />
                        </div>
                    )}
                    {title && !cta?.href && (
                        <div>
                            <Title
                                content={title}
                                className={styles['location-detail__title']}
                                tag={Title.TAG.H2}
                                variant={Title.VARIANT.H6}
                            />
                        </div>
                    )}
                    {(rootLocationName || parentLocationName) && (
                        <div className={styles['location-detail__parent-location']}>
                            <Typography
                                tag={Typography.TAG.P}
                                variant={Typography.VARIANT.T2B}
                                content={`at ${rootLocationName || parentLocationName}`}
                            />
                        </div>
                    )}
                    {buildingName && (
                        <div className={styles['location-detail__building-name']}>
                            <Typography
                                tag={Typography.TAG.P}
                                variant={Typography.VARIANT.T2}
                                content={buildingName}
                            />
                        </div>
                    )}
                    {infoList.length > 0 && (
                        <div className={styles['location-detail__list']}>
                            {infoList.map(([content, href, Icon]) => (
                                <IconDescription key={href} iconComponent={Icon}>
                                    {href ? (
                                        <Button
                                            href={href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            buttonStyle={Button.STYLE.TEXT_SMALL}
                                            label={content}
                                            analytics={{
                                                ...analyticsObj,
                                                interactionType:
                                                    content === phoneNumber
                                                        ? `${
                                                              analyticsObj.componentName ?? ''
                                                          }_phone_cta`
                                                        : `${
                                                              analyticsObj.componentName ?? ''
                                                          }_address_cta`,
                                            }}
                                        />
                                    ) : (
                                        <Typography
                                            content={content}
                                            variant={Typography.VARIANT.T2}
                                        />
                                    )}
                                </IconDescription>
                            ))}
                        </div>
                    )}

                    <div className={styles['location-detail__open-or-closed']}>
                        <LocationOpenOrClosed
                            workingHours={workingHours}
                            temporarilyClosed={temporarilyClosed}
                            overrideMessage={overrideMessage}
                            waitTime={waitTime}
                        />
                    </div>

                    {cta?.label && cta.href && (
                        <div className={styles['location-detail__actions']}>
                            <Button
                                className={styles['location-detail__button']}
                                label={cta.label}
                                href={cta.href}
                                buttonStyle={Button.STYLE.SECONDARY}
                                analytics={{
                                    ...analyticsObj,
                                    interactionType: `${analyticsObj.componentName ?? ''}_cta`,
                                }}
                                onClick={coveoAnalyticsCallbacks ? handleClick : undefined}
                                onContextMenu={
                                    coveoAnalyticsCallbacks ? handleContextMenu : undefined
                                }
                                onMouseDown={coveoAnalyticsCallbacks ? handleMouseDown : undefined}
                                onMouseUp={coveoAnalyticsCallbacks ? handleMouseUp : undefined}
                                onTouchEnd={coveoAnalyticsCallbacks ? handleTouchEnd : undefined}
                                onTouchStart={
                                    coveoAnalyticsCallbacks ? handleTouchStart : undefined
                                }
                            />
                        </div>
                    )}
                </div>

                <div className={styles['location-detail__media']}>
                    {buildingImage ? (
                        <Image
                            {...buildingImage}
                            fallbackSrc={DefaultImage}
                            defaultAspectRatio={Image.ASPECT_RATIO['16:9']}
                            roundedCorners
                        />
                    ) : (
                        <Image
                            src={DefaultImage}
                            defaultAspectRatio={Image.ASPECT_RATIO['16:9']}
                            roundedCorners
                            decorative
                        />
                    )}
                </div>

                {variant === LocationDetail.VARIANT.CARD && (
                    <div className={styles['location-detail__close-button']}>
                        <Button
                            buttonStyle={Button.STYLE.TERTIARY}
                            iconComponent={LazyIconX}
                            onClick={onCloseClick}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});

LocationDetail.displayName = 'LocationDetail';

LocationDetail.VARIANT = {
    CARD: 'card',
    LIST_ITEM: 'list-item',
    PROVIDER_LOCATION: 'provider-location',
};

LocationDetail.propTypes = {
    /**
     * Style variants
     */
    variant: PropTypes.oneOf(Object.values(LocationDetail.VARIANT)),
    /**
     * Location title
     */
    title: PropTypes.string,
    /**
     * Parent location name
     */
    parentLocationName: PropTypes.string,
    /**
     * Building name
     */
    buildingName: PropTypes.string,
    /**
     * Address object
     */
    address: addressPropType,
    /**
     * Phone number
     */
    phoneNumber: PropTypes.string,
    /**
     * Working hours
     */
    workingHours: workingHoursType,
    /**
     * CTA button, normally used to show more details
     */
    cta: PropTypes.shape(Button.propTypes),
    /**
     * Location image
     */
    buildingImage: PropTypes.shape(Image.propTypes),
    /**
     * Distance from current location
     */
    distance: PropTypes.string,
};
