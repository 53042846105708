import {
    buildFieldSortCriterion,
    buildInteractiveResult,
    buildResultList,
    loadAdvancedSearchQueryActions,
    loadQueryActions,
    loadSearchActions,
    loadSearchAnalyticsActions,
    SortOrder,
} from '@coveo/headless';
import { getHeadlessEngine } from 'libs/coveo/getHeadlessEngine';
import PropTypes from 'prop-types';
import React from 'react';

import { FeaturedLocations as FeaturedLocationsMolecule } from '@/design-system/molecules/FeaturedLocations/';
import { useLocalStorageGeolocation } from '@/hooks/useLocalStorageGeolocation';
import { mappingWorkingHours } from '@/utils/mappingWorkingHours';

import styles from './FeaturedLocations.module.scss';

const sortCriteria = buildFieldSortCriterion('mydistanceinmiles', SortOrder.Ascending);

/**
 * The Featured Locations component displays a list of featured locations.
 */
const FeaturedLocations = ({
    button,
    buttonType,
    coveo,
    locationCardOne,
    locationCardThree,
    locationCardTwo,
    coveoQuery,
    coveoAdvancedQuery,
    title,
    id,
    ...props
}) => {
    const [localStorageGeolocation] = useLocalStorageGeolocation();
    const [locations, setLocations] = React.useState([]);
    const isDynamicSearch = Boolean(coveoQuery || coveoAdvancedQuery);
    const pageSize = localStorageGeolocation ? 3 : 25;
    const fixedLocations = React.useMemo(
        () => [
            ...(locationCardOne ? locationCardOne : []),
            ...(locationCardTwo ? locationCardTwo : []),
            ...(locationCardThree ? locationCardThree : []),
        ],
        [locationCardOne, locationCardTwo, locationCardThree],
    );

    // Coveo search engine
    const engine = React.useMemo(() => {
        if (isDynamicSearch && coveo) {
            return getHeadlessEngine(`featured-locations-${id}`, {
                ...coveo,
                preprocessRequest: (request, _clientOrigin, metadata) => {
                    if (
                        !metadata ||
                        metadata.method !== 'search' ||
                        localStorageGeolocation == null
                    ) {
                        return request;
                    }

                    const body = JSON.parse(request.body);

                    body.queryFunctions = [
                        {
                            fieldName: '@mydistance',
                            function: `dist(@latitude, @longitude, ${
                                localStorageGeolocation.lat ?? 0
                            }, ${localStorageGeolocation.lng ?? 0})`,
                        },
                        {
                            fieldName: '@mydistanceinmiles',
                            function: '@mydistance*0.000621371',
                        },
                    ];

                    request.body = JSON.stringify(body);

                    return request;
                },
                analytics: { analyticsMode: 'legacy', trackingId: 'featured-locations' },
            });
        }
        return null;
    }, [isDynamicSearch, coveo, id, localStorageGeolocation]);

    const QueryActionCreators = React.useMemo(
        () => (engine ? loadQueryActions(engine) : null),
        [engine],
    );

    const SearchAnalyticsActionCreators = React.useMemo(
        () => (engine ? loadSearchAnalyticsActions(engine) : null),
        [engine],
    );

    const SearchActionCreators = React.useMemo(
        () => (engine ? loadSearchActions(engine) : null),
        [engine],
    );

    const AdvancedSearchActionCreators = React.useMemo(
        () => (engine ? loadAdvancedSearchQueryActions(engine) : null),
        [engine],
    );

    const controllerResultsList = React.useMemo(() => {
        if (engine) {
            return buildResultList(engine, {
                options: {
                    fieldsToInclude: [
                        'entitytype',
                        'loctype',
                        'locconditions',

                        // Distance
                        'mydistance',
                        'mydistanceinmiles',
                        'latitude',
                        'longitude',

                        // Open now
                        // 'locworkinghourssearch',

                        // Result List
                        'locvariant',
                        'locimgurl',
                        'locparent',
                        'locroot',
                        'locbuildingname',
                        'locaddrline1',
                        'locaddrline2',
                        'locaddrline3',
                        'locationstate',
                        'locationzip',
                        'locmapurl',
                        'locname',
                        'locphnum1',
                        'locworkinghours',
                        'locadjustedhours',
                        'locopen24hours',
                        'loctemporarilyclosed',
                        'locoverridemessage',
                        'locwaittime',
                        'locepicdepartmentid',
                    ],
                    sortCriteria,
                },
                initialState: {
                    numberOfResults: pageSize,
                },
            });
        }
        return null;
    }, [engine, pageSize]);

    /**
     * Execute a coveo search
     * @returns {function} The search action
     */
    const executeSearch = React.useCallback(() => {
        if (SearchActionCreators && SearchAnalyticsActionCreators) {
            return SearchActionCreators.executeSearch(
                SearchAnalyticsActionCreators.logInterfaceLoad(),
            );
        }
        return null;
    }, [SearchActionCreators, SearchAnalyticsActionCreators]);

    /**
     * Trigger a coveo search using the coveoQuery parameter
     * @returns {void}
     */
    const triggerExecuteSearch = React.useCallback(() => {
        if (engine || QueryActionCreators) {
            engine.dispatch(
                QueryActionCreators.updateQuery({
                    enableQuerySyntax: true,
                    q: coveoQuery,
                }),
            );
            engine.dispatch(
                AdvancedSearchActionCreators.registerAdvancedSearchQueries({
                    aq: coveoAdvancedQuery,
                }),
            );
            engine.dispatch(executeSearch());
        }
    }, [
        engine,
        QueryActionCreators,
        coveoQuery,
        AdvancedSearchActionCreators,
        coveoAdvancedQuery,
        executeSearch,
    ]);

    // Check if the component is static or dynamic
    React.useEffect(() => {
        if (isDynamicSearch) {
            // Trigger coveo location search
            triggerExecuteSearch();
        } else {
            // Set static cards from props
            setLocations(fixedLocations);
        }
    }, [
        locationCardOne,
        locationCardThree,
        locationCardTwo,
        isDynamicSearch,
        triggerExecuteSearch,
        controllerResultsList,
        fixedLocations,
    ]);

    React.useEffect(() => {
        if (isDynamicSearch && controllerResultsList) {
            /**
             * Subscribe to the results list
             * @returns {function} The unsubscribe function
             */
            const unsubscribe = controllerResultsList.subscribe(() => {
                // If geolocation is unavailable and there are more than 3 results
                // randomize the results and then only show the first 3
                const { results } = controllerResultsList.state;

                const slicedResults =
                    results && results.length > 3
                        ? results
                              .slice()
                              .sort(() => 0.5 - Math.random())
                              .slice(0, 3)
                        : results;

                const parsedResults = Array.isArray(slicedResults)
                    ? slicedResults.map((result) => {
                          const { uniqueId, title, uri, raw } = result;
                          const {
                              locworkinghours,
                              locadjustedhours,
                              locopen24hours,
                              loctemporarilyclosed,
                              locimgurl,
                              locaddrline1,
                              locaddrline2,
                              locaddrline3,
                              locationstate,
                              locationzip,
                              locmapurl,
                              locname,
                              locphnum1,
                              locvariant,
                              locparent,
                              locroot,
                              locbuildingname,
                              locwaittime,
                              locepicdepartmentid,
                              locoverridemessage,
                              latitude,
                              longitude,
                          } = raw;
                          let parsedWorkingHours = null;
                          let parsedAdjustedHours = null;

                          try {
                              parsedWorkingHours = locworkinghours && JSON.parse(locworkinghours);
                          } catch (e) {
                              console.error(e);
                          }

                          try {
                              parsedAdjustedHours =
                                  locadjustedhours && JSON.parse(locadjustedhours);
                          } catch (e) {
                              console.error(e);
                          }

                          let workingHours = mappingWorkingHours(
                              parsedWorkingHours,
                              parsedAdjustedHours,
                              `${locopen24hours}`.toLowerCase() === 'true',
                          );

                          for (const key in workingHours) {
                              const value = workingHours[key];
                              workingHours[key] = {
                                  ...value,
                                  closed: `${value.closed}`.toLowerCase() === 'true',
                              };
                          }
                          const temporarilyClosed =
                              `${loctemporarilyclosed}`.toLowerCase() === 'true';

                          let image;
                          if (locimgurl) {
                              image = {
                                  defaultImgSrc: locimgurl,
                              };
                          }

                          let address;
                          if (
                              [
                                  locaddrline1,
                                  locaddrline2,
                                  locaddrline3?.split(',')[0],
                                  locationstate?.[0],
                                  locationzip?.[0],
                                  locmapurl,
                              ].filter(Boolean).length > 0
                          ) {
                              address = {
                                  line1: locaddrline1,
                                  line2: locaddrline2,
                                  city: locaddrline3?.split(',')[0],
                                  state: locationstate?.[0],
                                  zipCode: locationzip?.[0],
                                  googleMapsUrl: locmapurl,
                              };
                          }

                          const interactiveResultController = engine
                              ? buildInteractiveResult(engine, {
                                    options: { result: result },
                                })
                              : null;

                          const content = {
                              variant: locvariant,
                              locationType: locvariant,
                              id: uniqueId,
                              title: locname || title,
                              buildingImage: image,
                              parentLocationName: locparent,
                              rootLocationName: locroot,
                              buildingName: locbuildingname,
                              address,
                              phoneNumber: locphnum1,
                              cta: {
                                  label: 'View Location Details',
                                  href: uri,
                              },
                              workingHours,
                              temporarilyClosed,
                              overrideMessage: locoverridemessage,
                              waitTime: `${locwaittime}`.toLowerCase() === 'true',
                              epicDepartmentId: locepicdepartmentid,
                              position: {
                                  lat: latitude,
                                  lng: longitude,
                              },
                              coveoAnalyticsCallbacks: interactiveResultController
                                  ? {
                                        cancelPendingSelect: () =>
                                            interactiveResultController.cancelPendingSelect(),
                                        onClick: () => interactiveResultController.select(),
                                        onContextMenu: () => interactiveResultController.select(),
                                        onMouseDown: () => interactiveResultController.select(),
                                        onMouseUp: () => interactiveResultController.select(),
                                        onTouchStart: () =>
                                            interactiveResultController.beginDelayedSelect(),
                                        onTouchEnd: () =>
                                            interactiveResultController.cancelPendingSelect(),
                                    }
                                  : undefined,
                          };

                          return {
                              ...content,
                              content,
                          };
                      })
                    : [];

                // Set the parsed locations, if there are no results use the fixed locations
                setLocations(parsedResults?.length === 0 ? fixedLocations : parsedResults);
            });

            return () => {
                // Unsubscribe from the results list
                unsubscribe();
            };
        }
    }, [controllerResultsList, isDynamicSearch, pageSize, fixedLocations, engine]);

    return (
        <div styles={styles['featured-locations']} {...props}>
            <FeaturedLocationsMolecule
                title={title}
                buttonType={buttonType}
                button={button}
                zip={isDynamicSearch ? localStorageGeolocation?.zipCode : null}
                locationCards={locations}
                htmlId={props.htmlId}
                {...props}
            />
        </div>
    );
};

FeaturedLocations.propTypes = {
    coveo: PropTypes.shape({
        organizationId: PropTypes.string,
        accessToken: PropTypes.string,
        platformUrl: PropTypes.string,
        renewAccessTokenUrl: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
    LocationCardOne: PropTypes.object,
    LocationCardTwo: PropTypes.object,
    LocationCardThree: PropTypes.object,
    buttonType: PropTypes.oneOf(['primary', 'secondary']),
    button: PropTypes.object,
    coveoQuery: PropTypes.string,
};

export default FeaturedLocations;
